import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { FeaturedArticles, Hero, MobileServices } from "../../containers"
import { BreadCrumb } from "../../components"
import MobileAppsCrumbs from "../../constants/BreadCrumbs/MobileAppsCrumbs"

const MobilePage = ({ data }) => {
  //console.log("Mobile data:", data)
  const {
    allStrapiArticle: { nodes: articles },
  } = data

  const image = getImage(
    data.strapiMobile.image.localFile.childImageSharp.gatsbyImageData
  )
  return (
    <section>
      <Hero image={image} title="Mobile Apps" />
      <BreadCrumb crumbs={MobileAppsCrumbs} />
      <MobileServices
        image={image}
        title={data.strapiMobile.title}
        text={data.strapiMobile.text}
      />
      <FeaturedArticles
        articles={articles}
        title="Featured Articles"
        styleClass="underline"
      />
      <div />
    </section>
  )
}

export default MobilePage

export const query = graphql`
  {
    strapiMobile {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
      text
    }
    allStrapiArticle(filter: { featured: { eq: true } }) {
      nodes {
        strapiId
        slug
        link
        title
        description
        date
        category {
          name
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 300
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        author {
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 50
                  height: 50
                  placeholder: BLURRED
                  formats: [AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`
